<template>
  <div>
    <ListProviders></ListProviders>
  </div>
</template>

<script>
import ListProviders from '@core/layouts/components/admin-components/ListProviders.vue'
export default {
  name: 'ApproveDrivers',
  components: {
    ListProviders,
  },
}
</script>

<style scoped>
</style>